export type ElementType = {
    type: ElementTypeEnum;
    label?: string;
};

//dev.azure.com/ingeniu/Workroads/_git/workroads.backend?version=GBdevelops/dev1&path=/Src/Prodify.Backend.Domain/Enums/ElementType.cshttps:
export enum ElementTypeEnum {
    Unknown,
    Point,
    Polyline,
    Line,
    Arc,
    Circle,
    Ellipse,
    Spline,
    Parabola,
    Dimension,
    Hatch,
    Text,
    Block,
    BlockDefinition,
    Drawing,
    Document,
    Assembly,
    Part,
    Component,
    InventorDrawing,
    Sheet,
    Sketch,
    Typology,
    GenericXmlObject
}

/**
 * Autocad exclusive element types. This elements are used to determine
 * the pattern target framework.
 *
 * If any of the pattern node types have the following types, then
 * a target framework for the pattern is Autocad.
 */
export const AUTOCAD_ELEMENT_TYPES = [
    ElementTypeEnum.Block,
    ElementTypeEnum.Line,
    ElementTypeEnum.BlockDefinition
];

/**
 * Inventor exclusive element types. This elements are used to determine
 * the pattern target framework.
 *
 * If any of the pattern node types have the following types, then
 * a target framework for the pattern is Inventor.
 */
export const INVENTOR_ELEMENT_TYPES = [
    ElementTypeEnum.Point,
    ElementTypeEnum.Polyline,
    ElementTypeEnum.Arc,
    ElementTypeEnum.Circle,
    ElementTypeEnum.Ellipse,
    ElementTypeEnum.Spline,
    ElementTypeEnum.Parabola,
    ElementTypeEnum.Dimension,
    ElementTypeEnum.Hatch,
    ElementTypeEnum.Text,
    ElementTypeEnum.Drawing,
    ElementTypeEnum.Document,
    ElementTypeEnum.Assembly,
    ElementTypeEnum.Part,
    ElementTypeEnum.Component,
    ElementTypeEnum.InventorDrawing,
    ElementTypeEnum.Sheet,
    ElementTypeEnum.Sketch
];

export const SOLIDWORKS_ELEMENT_TYPES = [
    ElementTypeEnum.Assembly,
    ElementTypeEnum.Part,
    ElementTypeEnum.Typology,
    ...INVENTOR_ELEMENT_TYPES
];

export const SOLIDEDGE_ELEMENT_TYPES = [
    ElementTypeEnum.Part,
    ElementTypeEnum.Assembly,
    ...INVENTOR_ELEMENT_TYPES
];
