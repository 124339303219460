import { DrawingTargetFramework } from '../definitions/enums';

export const TARGET_SOFTWARE_ICONS_PATH = './assets/softwares/';
export const WORKROADS = 'workroads';

export const TARGET_SOFTWARE_ICONS: { [key: string]: { image: string; name: string } } = {
    [DrawingTargetFramework.Unknown]: {
        image: TARGET_SOFTWARE_ICONS_PATH + 'source.png',
        name: 'Source'
    },
    [DrawingTargetFramework.AutoCAD]: {
        image: TARGET_SOFTWARE_ICONS_PATH + 'autocad.png',
        name: 'AutoCad'
    },
    [DrawingTargetFramework.Inventor]: {
        image: TARGET_SOFTWARE_ICONS_PATH + 'inventor.png',
        name: 'Inventor'
    },
    [DrawingTargetFramework.SolidWorks]: {
        image: TARGET_SOFTWARE_ICONS_PATH + 'solidworks.png',
        name: 'SolidWorks'
    },
    [DrawingTargetFramework.GenericXml]: {
        image: TARGET_SOFTWARE_ICONS_PATH + 'xml.png',
        name: 'Xml'
    },
    [DrawingTargetFramework.SolidEdge]: {
        image: TARGET_SOFTWARE_ICONS_PATH + 'solidedge.png',
        name: 'SolidEdge'
    },
    [WORKROADS]: {
        image: TARGET_SOFTWARE_ICONS_PATH + 'icon.png',
        name: 'Workroads'
    }
};

export const FRAMEWORK_TRANSFERS_PATH = './assets/framework_tranfers/';

export const FRAMEWORK_TRANSFERS_ICONS: {
    [key: string]: { image: string; name: string; image_transparent: string };
} = {
    [DrawingTargetFramework.AutoCAD]: {
        image: FRAMEWORK_TRANSFERS_PATH + 'autocad_to_workroads.png',
        name: 'AutoCad',
        image_transparent:
            FRAMEWORK_TRANSFERS_PATH + 'autocad_to_workroads_transparent.png'
    },
    [DrawingTargetFramework.Inventor]: {
        image: FRAMEWORK_TRANSFERS_PATH + 'inventor_to_workroads.png',
        name: 'Inventor',
        image_transparent:
            FRAMEWORK_TRANSFERS_PATH + 'inventor_to_workroads_transparent.png'
    },
    [DrawingTargetFramework.SolidWorks]: {
        image: FRAMEWORK_TRANSFERS_PATH + 'solidworks_to_workroads.png',
        name: 'SolidWorks',
        image_transparent:
            FRAMEWORK_TRANSFERS_PATH + 'solidworks_to_workroads_transparent.png'
    },
    [DrawingTargetFramework.SolidEdge]: {
        image: FRAMEWORK_TRANSFERS_PATH + 'solidedge_to_workroads.png',
        name: 'SolidEdge',
        image_transparent:
            FRAMEWORK_TRANSFERS_PATH + 'solidedge_to_workroads_transparent.png'
    },
    [DrawingTargetFramework.GenericXml]: {
        image: FRAMEWORK_TRANSFERS_PATH + 'xml_to_workroads.png',
        name: 'Xml',
        image_transparent: FRAMEWORK_TRANSFERS_PATH + 'xml_to_workroads_transparent.png'
    }
};
